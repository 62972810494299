/** @jsx jsx */

import MySEO from '../components/seo'
//import Link from '../components/Link'
import Layout from '../components/layout'
import {jsx,Flex,Box,Text,Heading} from 'theme-ui'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../components/Link'

export default function Tip(){
  
  return(
    <Layout>
      <MySEO title="Veranstaltungen vom K.O.M. " description="Geschichtsverein" />
      <Heading sx={{letterSpacing:'0.08rem',ml:"2"}}>Kulturtipps</Heading>
   <Flex sx={{flexDirection:["column","column","column","row"]}}>
   <Box id="Kramer" sx={{flex:1,m:2}}>
    <Heading sx={{
              fontWeight:600,
              textDecoration: `none`,
              fontSize:[1,2],
              color:`text`,
             
          }} >
             Prof. Dieter Kramer: 
          <br/>
           Gemeinsinn und Kreativität<br/>

           <Text sx={{mt:2,fontWeight:400,fontSize:[0,1]}}>
            Geschichten vom Mittelrhein und der Lahn<br/>
           Imprimatur, Verlag Rudolf Kring, Lahnstein<br/>
           ISBN 978-3-947874-10-1
           
          </Text>    
          </Heading>
          <Box sx={{mb:2}}>
   <StaticImage src="../images/tipps/dieter.jpg" width={480}/>
   <Text sx={{fontSize:0}}>Prof. Dr. Dieter Kramer, Europäischer Ethnologe</Text>
   </Box>
   <a rel="noreferrer noopener" target="_blank"href="https://regionalgeschichte-oberes-mittelrheintal.netlify.app/">
    Zum Blog des Ethnologen Prof.Dr. Dieter Kramer
   </a>
   <Text>
          Dort finden Sie auch ein Inhaltsverzeichnis des Buchs.
  </Text>
<Text>
Der Blog und das aus dem Blog hervorgegangene Buch zeichnen sich aus durch den kenntnisreichen und kritischen Blick auf die Gegenwart und die Geschichte des Oberen Mittelrheintals.<br/>
Es ist aber kein historisches Werk, sondern Prof. Dr. Dieter Kramer erzählt Geschichten von der Region, in denen die regionale Geschichte exemplarisch verdeutlicht wird. Es wird wohl keinen Leser geben, der nicht auf unbekannte Zusammenhänge stoßen wird.   
</Text>
 

    </Box>

   
   
    <Box id="Enderwitz" sx={{flex:1,m:2}}>
    <Heading sx={{
              fontWeight:600,
              textDecoration: `none`,
              fontSize:[1,2],
              color:`text`,
             
          }} >
             Prof. Susanne Enderwitz: 
          <br/>
           Tausendundeine Nacht als Weltliteratur<br/>

           <Text sx={{mt:2,fontWeight:400,fontSize:[0,1]}}>Dienstag, 29. November 2022,  Beginn: 16:30<br/>
           geplant sind 10 Veranstaltungen<br/>
          Ort: Weinhaus Weiler in Oberwesel (am Marktplatz)
           
          </Text>    
          </Heading>
          <Box sx={{mb:2}}>
   <StaticImage src="../images/tipps/enderwitz.jpg" width={480}/>
   <Text sx={{fontSize:0}}>Prof. Dr. Susanne Enderwitz, Islam-Wissenschaftlerin</Text>
   </Box>
   <Text>
          "Wir bewegen uns aus der höchsten in die niedrigste Welt, vom Kalifen zum Barbier, vom armseligen Fischer zum fürstlichen Kaufherrn, und es ist eine Menschlichkeit, die uns umgibt, mit breiter, leichter Woge uns hebt und trägt; wir sind unter Geistern, unter Zauberern, unter Dämonen und fühlen uns wiederum zu Hause" (Hugo von Hoffmannsthal) </Text>
<Text>Ohne die westliche (europäische, später auch amerikanische) Begeisterung über "Tausendundeine Nacht" hätte dieses Buch wohl nie die Grenzen des arabischen Sprachraums überschritten und seine einzigartige Weltgeltung erlangt. Seit mehr als drei Jahrhunderten gehört es zum Grundbestand westlicher Literatur, und dabei greift es auch auf die zeitgenössischen Medien aus: Im Oktober 2022 verkündete die Disney Corporation, alle Märchen und Geschichten aus "Tausendundeine(r) Nacht" verfilmen zu wollen.
</Text>
<Text><span sx={{textDecoration:'underline'}}>In zehn Wochen</span> wollen wir uns auf die Spuren von "Tausendundeine(r) Nacht" begeben, und da die sagenhafte Karriere des Werks im Westen begann, werden wir uns auch gleich zu Anfang der Geschichte seiner Übersetzung widmen. In der zweiten Woche wollen wir uns dann mit der Herkunft des Textes und seiner weiteren Geschichte befassen und gleichzeitig einen Überblick über dieses monumentale Werk erhalten.</Text>
<Text>Es folgt die gemeinsame Lektüre von fünf Geschichten aus "Tausendundeiner Nacht", die von einem Märchen aus dem ältesten Bestand bis hin zu einer Abenteuergeschichte aus der jüngsten Entstehungsschicht reicht. Darunter sind Erzählungen, die in Europa weniger bekannt sind (Die Geschichte des Prinzen Bedr), aber auch die in Europa wohl bekannteste und beliebteste Erzählung (Die Geschichte Alaeddins).</Text>
<Text>
Zum Schluss wollen wir uns gemeinsam den Zeichentrickfilm "Aladdin" (Disney) ansehen und nach dem Einfluss von "Tausendundeine(r) Nacht" auf die europäische Kultur fragen.
</Text>
<Text><strong>Text:</strong> Die Übersetzung von Gustav Weil (Die Märchen aus 1001 Nacht - Vollständige Ausgabe durchgehend illustriert, 2 Bände, 14,95 Euro).</Text>
<Text sx={{fontWeight:600}}>Teilnahme noch möglich. Ab Januar 2023 sind noch 7 Veranstaltungen geplant.
</Text>
<Text>Kontakt: enderwitz@kulturnetz-oberes-mittelrheintal.org</Text>

<Link to="/pdf/1001seminarplan.pdf">Seminarplan</Link>
    </Box>

   </Flex>
   <hr/>


          <Flex sx={{flexDirection:["column","column","row"]}}>
  
          {
   new Date('2023-12-12')>Date.now()?
    <Box  id="kunsthaus" sx={{flex:1,m:2,maxWidth:550}}>
      <Heading>
        Das war ein großartiger Abend. Vielen Dank an unser K.O.M-Mitglied und Pianisten Benjamin Reiter und die Geigerin Ana Paola Alarcó!
      </Heading>
    <Heading sx={{
              fontWeight:600,
              textDecoration: `none`,
              fontSize:[1,2],
              color:`text`,
              maxWidth: '500px'
          }} >Salon N° 3
          <br/>
          Muy apasionado<br/>
          <Text sx={{mt:2,fontWeight:400,fontSize:[0,1]}}>
          Sonntag, 11. Dezember,  16:30 Uhr<br/>
          Ort: Kulturhaus Oberwesel<br/>
          Einlass: ab 16:00, Eintritt: 15,- €
           </Text>
          </Heading>
          <Box sx={{mb:2}}>
            <StaticImage src="../images/salons/salon3/konzertkulturhaus.jpeg" width={520} alt="Die Künstler"/>
            <Text sx={{fontSize:0}}>Benjamin Reiter, Ana Paola Alarcó </Text>
          </Box>
          <Text>
                Lassen Sie sich kurz vor Weihnachten noch einmal musikalisch verführen mit <span style={{fontWeight:500}}>Ana Paola Alarcón (Violine)</span> und <span style={{fontWeight:500}}>Benjamin Reiter (Klavier)</span>. </Text>

                <Text>In ihrem abwechslungsreichen Programm im Kulturhaus Oberwesel bauen sie <span style={{fontStyle:'italic'}}>eine Brücke von der traditionellen deutschen Klassik über die französisch-belgische Spätromantik bis zur zeitgenössischen klassischen Musik Lateinamerikas</span>.
                </Text>
                <Text>
                Der gebürtige Bacharacher Benjamin Reiter und Ana Paola Alarcón präsentieren ein Repertoire von großer Spannbreite: </Text>
                <Text>Die Sonate D-Dur Op. 12 Nr. 1 von <span style={{fontWeight:600}}>Ludwig van Beethoven</span> ist eine stets frisch wirkende Eröffnung, während die große Violinsonate von <span style={{fontWeight:600}}>Caesar Franck </span>eine vielfältige Klangwelt zwischen verträumt-atmosphärischen Flächen und höchster Dramatik eröffnet.
                </Text>  
                <Text mb="3">
                Mit dem zeitgenössischen, ursprünglich als Bühnenmusik zum gleichnamigen Theaterstück konzipierten Werk „Diogenes y las camisas voladoras“ des <span style={{fontWeight:600}}>venezolanischen Komponisten Icli Zitella</span>, den Ana Paola Alarcón aus ihrer Heimatstadt Caraccas persönlich kennt, wird ein spannungsvoller Kontrast gesetzt. 
                </Text>

    

    </Box>
    :null
   }

   
   </Flex>

    </Layout>
  )
}
 
   
  

 

 


